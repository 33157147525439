import {
  mdiViewDashboardVariant,
  mdiLogout,
  mdiAccountSettingsOutline,
  mdiViewQuilt,
  mdiPen,
  mdiTooltipEdit,
  mdiThumbsUpDown,
  mdiEmailArrowRight,
  mdiEmailArrowRightOutline,
  mdiEmailArrowLeftOutline,
  mdiStepBackward2,
  mdiEmailCheckOutline,
  mdiEmailCheck,
  mdiTextBoxRemoveOutline ,
  mdiAccountArrowLeftOutline,
  mdiAccountCogOutline,
  mdiFileTree,
  mdiApplicationCog,
  mdiInboxArrowDown,
  mdiTableSearch,
  mdiHomeCity,
  mdiMailboxOpenUp,
} from "@mdi/js";
const data = {
  appSidebarMenu:
  {
    menuDir:
    {
      taskMenu: [
        { parent: "/memo", userType: "DIREKTUR", to: "/memo/draft-approval", slug: "memo_revision", title: "menu.draft-approval-list", icon: mdiThumbsUpDown },
        { parent: "/memo", userType: "all", to: "/memo/sent", slug: "memo_sent", title: "menu.sent-list", icon: mdiEmailArrowRightOutline },
        { parent: "/memo", userType: "all", to: "/memo/sentx", slug: "memo_sent_x", title: "menu.sentx-list", icon: mdiEmailArrowRight },
        { parent: "/memo", userType: "all", to: "/memo/revoked", slug: "memo_revoke", title: "menu.revoked-list", icon: mdiStepBackward2 },
        { parent: "/memo", userType: "all", to: "/memo/terminated", slug: "memo_trash", title: "menu.terminated", icon: mdiTextBoxRemoveOutline  , customClass:'mt-4'}
      ],
      memoMenu: [
        { parent: "/", userType: "all", to: "/dashboard", slug: "dashboard", title: "menu.dashboard", icon: mdiViewDashboardVariant },
        { parent: "/", title: null, to: null, slug: "separator", icon: null },
        { parent: "/memo", userType: "all", to: "/memo/permit", slug: "memo_permit_inbox", title: "menu.permit-inbox-list", icon: mdiEmailCheckOutline },
        { parent: "/memo", userType: "all", to: "/memo/inbox", slug: "memo_inbox", title: "menu.inbox-list", icon: mdiEmailArrowLeftOutline },
        { parent: "/memo", userType: "all", to: "/memo/incoming", slug: "incoming_inbox", title: "menu.incoming-list", icon: mdiInboxArrowDown  },
      ]
    },
    menuSevp:
    {
      taskMenu: [
        { parent: "/memo", userType: "DIREKTUR", to: "/memo/draft-approval", slug: "memo_revision", title: "menu.draft-approval-list", icon: mdiThumbsUpDown },
        { parent: "/memo", userType: "all", to: "/memo/sent", slug: "memo_sent", title: "menu.sent-list", icon: mdiEmailArrowRightOutline },
        { parent: "/memo", userType: "all", to: "/memo/sentx", slug: "memo_sent_x", title: "menu.sentx-list", icon: mdiEmailArrowRight },
        { parent: "/memo", userType: "all", to: "/memo/revoked", slug: "memo_revoke", title: "menu.revoked-list", icon: mdiStepBackward2 },
        { parent: "/memo", userType: "all", to: "/memo/terminated", slug: "memo_trash", title: "menu.terminated", icon: mdiTextBoxRemoveOutline  , customClass:'mt-4'}
      ],
      memoMenu: [
        { parent: "/", userType: "all", to: "/dashboard", slug: "dashboard", title: "menu.dashboard", icon: mdiViewDashboardVariant },
        { parent: "/", title: null, to: null, slug: "separator", icon: null },
        { parent: "/memo", userType: "all", to: "/memo/permit", slug: "memo_permit_inbox", title: "menu.permit-inbox-list", icon: mdiEmailCheckOutline },
        { parent: "/memo", userType: "all", to: "/memo/inbox", slug: "memo_inbox", title: "menu.inbox-list", icon: mdiEmailArrowLeftOutline },
        { parent: "/memo", userType: "all", to: "/memo/incoming", slug: "incoming_inbox", title: "menu.incoming-list", icon: mdiInboxArrowDown  },
        { parent: "/task", userType: "all", to: "/task/disposition", slug: "task_disposition", title: "menu.sevp-disposition-list", icon: mdiAccountArrowLeftOutline },
      ]
    },
    menuPjp: {
      memoMenu: [
        { parent: "/", userType: "all", to: "/dashboard", slug: "dashboard", title: "menu.dashboard", icon: mdiViewDashboardVariant },
        { parent: "/", title: null, to: null, slug: "separator", icon: null },
        { parent: "/memo", userType: "DIREKTUR PJP PJP_KANPUS", to: "/memo/draft-approval", slug: "memo_revision", title: "menu.draft-approval-list", icon: mdiThumbsUpDown },
        { parent: "/memo", userType: "all", to: "/memo/sent", slug: "memo_sent", title: "menu.sent-list", icon: mdiEmailArrowRightOutline },
        { parent: "/memo", userType: "all", to: "/memo/sentx", slug: "memo_sent_x", title: "menu.sentx-list", icon: mdiEmailArrowRight },
      ],
      taskMenu: [
        { parent: "/memo", userType: "all", to: "/memo/inbox", slug: "memo_inbox", title: "menu.inbox-list", icon: mdiEmailArrowLeftOutline },
        { parent: "/task", userType: "all", to: "/task/disposition", slug: "task_disposition", title: "menu.disposition-list", icon: mdiAccountArrowLeftOutline },
        { parent: "/memo", userType: "all", to: "/memo/revoked", slug: "memo_revoke", title: "menu.revoked-list", icon: mdiStepBackward2, customClass:'mt-4' }, 
        { parent: "/memo", userType: "all", to: "/memo/terminated", slug: "memo_trash", title: "menu.terminated", icon: mdiTextBoxRemoveOutline },
      ]
    },
    menuPjpSekper: {
      memoMenu: [
        { parent: "/", userType: "all", to: "/dashboard", slug: "dashboard", title: "menu.dashboard", icon: mdiViewDashboardVariant },
        { parent: "/", title: null, to: null, slug: "separator", icon: null },
        { parent: "/memo", userType: "DIREKTUR PJP PJP_KANPUS", to: "/memo/draft-approval", slug: "memo_revision", title: "menu.draft-approval-list", icon: mdiThumbsUpDown },
        { parent: "/memo", userType: "all", to: "/memo/sent", slug: "memo_sent", title: "menu.sent-list", icon: mdiEmailArrowRightOutline },
        { parent: "/memo", userType: "all", to: "/memo/sentx", slug: "memo_sent_x", title: "menu.sentx-list", icon: mdiEmailArrowRight },
        { parent: "/memo", userType: "all", to: "/memo/cc-sentx", slug: "memo_do_sent_x", title: "menu.cc-sentx-list", icon: mdiMailboxOpenUp },
      ],
      taskMenu: [
        { parent: "/memo", userType: "all", to: "/memo/inbox", slug: "memo_inbox", title: "menu.inbox-list", icon: mdiEmailArrowLeftOutline },
        { parent: "/task", userType: "all", to: "/task/disposition", slug: "task_disposition", title: "menu.disposition-list", icon: mdiAccountArrowLeftOutline },
        { parent: "/memo", userType: "all", to: "/memo/revoked", slug: "memo_revoke", title: "menu.revoked-list", icon: mdiStepBackward2, customClass:'mt-4' }, 
        { parent: "/memo", userType: "all", to: "/memo/terminated", slug: "memo_trash", title: "menu.terminated", icon: mdiTextBoxRemoveOutline },
      ]
    },
    menuKarpim: {
      memoMenu: [
        { parent: "/", userType: "all", to: "/dashboard", slug: "dashboard", title: "menu.dashboard", icon: mdiViewDashboardVariant },
        { parent: "/", title: null, to: null, slug: "separator", icon: null },
        { parent: "/memo", userType: "all", to: "/memo/draft", slug: "memo-draft", title: "menu.draft-list", icon: mdiPen },
        { parent: "/memo", userType: "DIREKTUR PJP PJP_KANPUS KASUBAG KADIS KARPIM KARPIM_KANPUS", to: "/memo/revision", slug: "memo_revision", title: "menu.revision-list", icon: mdiTooltipEdit },
        { parent: "/memo", userType: "all", to: "/memo/sent", slug: "memo_sent", title: "menu.sent-list", icon: mdiEmailArrowRightOutline },
        { parent: "/memo", userType: "all", to: "/memo/sentx", slug: "memo_sent_x", title: "menu.sentx-list", icon: mdiEmailArrowRight },
      ],
      taskMenu: [
        { parent: "/memo", userType: "all", to: "/memo/inbox", slug: "memo_inbox", title: "menu.inbox-list", icon: mdiEmailArrowLeftOutline },
        { parent: "/task", userType: "all", to: "/task/disposition", slug: "task_disposition", title: "menu.disposition-list", icon: mdiAccountArrowLeftOutline },
        { parent: "/memo", userType: "all", to: "/memo/revoked", slug: "memo_revoke", title: "menu.revoked-list", icon: mdiStepBackward2, customClass:'mt-4' },
        { parent: "/memo", userType: "all", to: "/memo/terminated", slug: "memo_trash", title: "menu.terminated", icon: mdiTextBoxRemoveOutline}
      ]
    },
    menuKrani: {
      memoMenu: [
        { parent: "/", userType: "all", to: "/dashboard", slug: "dashboard", title: "menu.dashboard", icon: mdiViewDashboardVariant },
        { parent: "/", title: null, to: null, slug: "separator", icon: null },
        { parent: "/memo", userType: "all", to: "/memo/draft", slug: "memo-draft", title: "menu.draft-list", icon: mdiPen },
        { parent: "/memo", userType: "all", to: "/memo/sent", slug: "memo_sent", title: "menu.sent-list", icon: mdiEmailArrowRightOutline },
        { parent: "/memo", userType: "all", to: "/memo/sentx", slug: "memo_sent_x", title: "menu.sentx-list", icon: mdiEmailArrowRight },
      ],
      taskMenu: [
        { parent: "/memo", userType: "all", to: "/memo/inbox", slug: "memo_inbox", title: "menu.inbox-list", icon: mdiEmailArrowLeftOutline },
        { parent: "/task", userType: "all", to: "/task/disposition", slug: "task_disposition", title: "menu.disposition-list", icon: mdiAccountArrowLeftOutline },
        { parent: "/memo", userType: "all", to: "/memo/terminated", slug: "memo_trash", title: "menu.terminated", icon: mdiTextBoxRemoveOutline  , customClass:'mt-4'},
        { parent: "/memo", userType: "all", to: "/memo/revoked", slug: "memo_revoke", title: "menu.revoked-list", icon: mdiStepBackward2 },
      ]
    },
    menuKSSekper: {
      memoMenu: [
        { parent: "/", userType: "all", to: "/dashboard", slug: "dashboard", title: "menu.dashboard", icon: mdiViewDashboardVariant },
        { parent: "/", title: null, to: null, slug: "separator", icon: null },
        { parent: "/memo", userType: "all", to: "/memo/draft", slug: "memo-draft", title: "menu.draft-list", icon: mdiPen },
        { parent: "/memo", userType: "all", to: "/memo/sent", slug: "memo_sent", title: "menu.sent-list", icon: mdiEmailArrowRightOutline },
        { parent: "/memo", userType: "all", to: "/memo/sentx", slug: "memo_sent_x", title: "menu.sentx-list", icon: mdiEmailArrowRight },
        { parent: "/memo", userType: "all", to: "/memo/cc-sentx", slug: "memo_do_sent_x", title: "menu.cc-sentx-list", icon: mdiMailboxOpenUp },
      ],
      taskMenu: [
        { parent: "/memo", userType: "all", to: "/memo/inbox", slug: "memo_inbox", title: "menu.inbox-list", icon: mdiEmailArrowLeftOutline },
        { parent: "/task", userType: "all", to: "/task/disposition", slug: "task_disposition", title: "menu.disposition-list", icon: mdiAccountArrowLeftOutline },
        { parent: "/memo", userType: "all", to: "/memo/terminated", slug: "memo_trash", title: "menu.terminated", icon: mdiTextBoxRemoveOutline  , customClass:'mt-4'},
        { parent: "/memo", userType: "all", to: "/memo/revoked", slug: "memo_revoke", title: "menu.revoked-list", icon: mdiStepBackward2 },
      ]
    },
    menuSekretaris:{
        memoMenu: [
        { parent: "/", userType: "all", to: "/dashboard", slug: "dashboard", title: "menu.dashboard", icon: mdiViewDashboardVariant },
        { parent: "/", title: null, to: null, slug: "separator", icon: null },
        { parent: "/memo", userType: "all", to: "/memo/sent", slug: "memo_sent", title: "menu.sent-list", icon: mdiEmailArrowRightOutline },
        { parent: "/memo", userType: "all", to: "/memo/sentx", slug: "memo_sent_x", title: "menu.sentx-list", icon: mdiEmailCheck },
      ],
      taskMenu: [
        { parent: "/memo", userType: "all", to: "/memo/inbox", slug: "memo_inbox", title: "menu.inbox-list", icon: mdiEmailArrowLeftOutline },
        { parent: "/task", userType: "all", to: "/task/disposition", slug: "task_disposition", title: "menu.disposition-list", icon: mdiAccountArrowLeftOutline },
      ]
    },
    menuSekretarisDirSevp:{
        memoMenu: [
        { parent: "/", userType: "all", to: "/dashboard", slug: "dashboard", title: "menu.dashboard", icon: mdiViewDashboardVariant },
        { parent: "/", title: null, to: null, slug: "separator", icon: null },
        { parent: "/memo", userType: "all", to: "/memo/sent", slug: "memo_sent", title: "menu.sent-list", icon: mdiEmailArrowRightOutline },
        { parent: "/memo", userType: "all", to: "/memo/sentx", slug: "memo_sent_x", title: "menu.sentx-list", icon: mdiEmailCheck },
      ],
      taskMenu: [
        { parent: "/memo", userType: "all", to: "/memo/permit", slug: "memo_permit_inbox", title: "menu.permit-inbox-list", icon: mdiEmailCheckOutline },
        { parent: "/memo", userType: "all", to: "/memo/inbox", slug: "memo_inbox", title: "menu.inbox-list", icon: mdiEmailArrowLeftOutline },
        { parent: "/task", userType: "all", to: "/task/disposition", slug: "task_disposition", title: "menu.disposition-list", icon: mdiAccountArrowLeftOutline },
      ]
    },
    menuAdmin: [
      // { parent: "/setting", userType: "admin", to: "/setting/officials", slug: "setting_officials", title: "menu.setting-officials", icon: mdiAccountCogOutline },
      { parent: "/setting", userType: "admin", to: "/setting/subordinate", slug: "subordinate_mapping", title: "menu.subordinate-mapping", icon: mdiFileTree },
    ],
    menuSuperAdmin: [
      { parent: "/setting", userType: "admin", to: "/setting/officials", slug: "setting_officials", title: "menu.setting-officials", icon: mdiAccountCogOutline },
      { parent: "/setting", userType: "admin", to: "/setting/subordinate", slug: "subordinate_mapping", title: "menu.subordinate-mapping", icon: mdiFileTree },
      { parent: "/setting", userType: "admin", to: "/setting/application", slug: "app_setting", title: "menu.app-setting", icon: mdiApplicationCog }
    ],
    menuMasterLembaga:[
      { parent: "/setting", userType: "admin", to: "/setting/institutions", slug: "setting_institutions", title: "menu.setting-institutions", icon: mdiHomeCity },
    ],
    menuReport:[
      { parent: "/report", userType: "all", to: "/report", slug: "report", title: "menu.report-elemen4", icon: mdiTableSearch },
    ]
  },
  appHeaderMenu: [
    { id: 0, userType: "all", slug: "toggle_sidebar", title: "menu.toggle-sidebar", icon: mdiViewQuilt },
    { id: 1, userType: "all", slug: "account_setting", title: "menu.settings", icon: mdiAccountSettingsOutline },
    { id: 2, userType: "all", slug: "logout", title: "menu.logout", icon: mdiLogout }
  ]
};

export default data;