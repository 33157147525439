<template>
  <v-app id="inspire">
    <Header v-model="expandOnHover"></Header>
    <Sidebar :expand-on-hover.sync="expandOnHover"></Sidebar>
    <v-main>
      <v-container class="fill-height mb-2" fluid>
        <transition name="fade">
          <router-view />
        </transition>
      </v-container>
    </v-main>

    <Footer></Footer>

    <create-general-memo-modal></create-general-memo-modal>
    <create-x-letter-modal></create-x-letter-modal>
    <create-permit-memo-modal></create-permit-memo-modal>
    <create-incoming-letter-modal></create-incoming-letter-modal>
    <help-group-recipient-dialog></help-group-recipient-dialog>

    <v-snackbar
      v-model="appSnackbarState"
      :multi-line="true"
      :top="true"
      :centered="true"
      :timeout="5000"
    >
      <p v-html="$t(appSnackbar.title)" class="snackbar--header"></p>
      <p v-html="$t(appSnackbar.message)"></p>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="appSnackbarState = false"
        >
          {{ $t("button.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="primary"
      @click="toTop"
    >
      <v-icon>{{iconArrowUp}}</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import helpGroupRecipientDialog from "../components/commonComponents/HelpGroupRecipientModal";
import Header from "./header/Header";
import Sidebar from "./sidebar/Sidebar";
import Footer from "./footer/Footer";
import createGeneralMemoModal from "@/components/commonComponents/CreateGeneralMemoModal";
import createXLetterModal from "@/components/commonComponents/CreateXLetterModal";
import createPermitMemoModal from "@/components/commonComponents/CreatePermitMemoModal";
import createIncomingLetterModal from "@/components/commonComponents/CreateIncomingLetterModal";
import { mapState, mapMutations } from "vuex";
import { mdiChevronUp } from '@mdi/js';
export default {
  name: "Layout",

  components: {
    Header,
    Sidebar,
    Footer,
    createGeneralMemoModal,
    createXLetterModal,
    createPermitMemoModal,
    createIncomingLetterModal,
    helpGroupRecipientDialog,
  },
  data: () => ({
    iconArrowUp: mdiChevronUp,
    fab: false
  }),
  computed: {
    ...mapState([
      "Customizer_drawer",
      "loaderMessage",
      "appMessageBox",
      "appSnackbar",
    ]),
    ...mapState("draft", ["editGeneralMemo", "editPermitMemo","editXLetter"]),
    appSnackbarState: {
      get() {
        return this.$store.state.appSnackbar.show;
      },
      set(val) {
        this.$store.commit("SET_APP_SNACKBAR_STATE", val);
      },
    },
    expandOnHover: {
      get() {
        return this.$store.state.expandOnHover;
      },
      set(val) {
        this.$store.commit("SET_EXPAND_ON_HOVER", val);
      },
    },
  },

  methods: {
    ...mapMutations({
      setCustomizerDrawer: "SET_CUSTOMIZER_DRAWER",
    }),
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  },
};
</script>
