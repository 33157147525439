<template>
   <div>
     <v-dialog
       content-class="create-x-letter-memo-modal"
       v-model="createXLetter"
       persistent
       scrollable
       max-width="960"
       origin="center center"
       overlay-opacity=".3"
     >
       <v-card
         id="CreateXLetter"
         class="height-95vh"
         v-if="approverXList && approverXList.length > 0"
       >
         <v-card-title class="pa-0">
           <v-toolbar flat>
             <v-toolbar-title>
               {{ $t("card.create-x-letter-memo") }}
             </v-toolbar-title>
             <v-spacer></v-spacer>
             <v-tooltip
               bottom
               v-if="!$vuetify.breakpoint.smAndDown && form.is_masuk"
             >
               <template v-slot:activator="{ on, attrs }">
                 <v-btn
                   v-bind:data-hint="$t('introjs.this-button-for-submit-action')"
                   data-hintposition="middle-middle"
                   data-position="bottom-right-aligned"
                   v-bind:loading="axiosProcessing"
                   v-bind:disabled="axiosProcessing"
                   color="blue-grey"
                   class="ma-2 white--text"
                   @click="menuAction('direct_send')"
                   v-on="on"
                   v-bind="attrs"
                 >
                   <v-icon dark class="mr-2">
                     {{ iconSubmit }}
                   </v-icon>
                   {{ $t("button.send") }}
                 </v-btn>
               </template>
               <span>{{ $t("tooltip.send-memo") }}</span>
             </v-tooltip>
 
             <!-- ini untuk surat masuk -->
             <v-tooltip
               bottom
               v-if="
                 !$vuetify.breakpoint.smAndDown && userIsKrani && !form.is_masuk
               "
             >
               <template v-slot:activator="{ on, attrs }">
                 <v-btn
                   v-bind:data-hint="$t('introjs.this-button-for-submit-action')"
                   data-hintposition="middle-middle"
                   data-position="bottom-right-aligned"
                   v-bind:loading="axiosProcessing"
                   v-bind:disabled="axiosProcessing"
                   color="blue-grey"
                   class="ma-2 white--text"
                   @click="menuAction('submit')"
                   v-on="on"
                   v-bind="attrs"
                 >
                   <v-icon dark class="mr-2">
                     {{ iconSubmit }}
                   </v-icon>
                   {{ $t("button.submit") }}
                 </v-btn>
               </template>
               <span>{{ $t("tooltip.submit-to-upline") }}</span>
             </v-tooltip>
 
             <v-menu
               v-if="
                 !$vuetify.breakpoint.smAndDown && !userIsKrani && !form.is_masuk
               "
               bottom
               left
             >
               <template v-slot:activator="{ on: menu, attrs }">
                 <v-tooltip bottom>
                   <template v-slot:activator="{ on: tooltip }">
                     <v-btn
                       v-bind:data-hint="
                         $t('introjs.this-button-for-submit-action')
                       "
                       v-bind:disabled="axiosProcessing"
                       v-bind:loading="axiosProcessing"
                       data-hintposition="middle-middle"
                       data-position="bottom-right-aligned"
                       color="blue-grey"
                       dark
                       v-bind="attrs"
                       v-on="{ ...tooltip, ...menu }"
                     >
                       <v-icon class="mr-2">{{ iconSubmit }}</v-icon>
                       {{ $t("button.submit") }}
                     </v-btn>
                   </template>
                   <span>{{ $t("tooltip.select-submit-to") }}</span>
                 </v-tooltip>
               </template>
               <v-list>
                 <v-list-item
                   v-for="(item, i) in menus"
                   v-bind:key="i"
                   @click="menuAction(item.command)"
                 >
                   <v-icon class="mr-3">{{ item.icon }}</v-icon>
                   <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                 </v-list-item>
               </v-list>
             </v-menu>
 
             <v-tooltip
               bottom
               v-if="!$vuetify.breakpoint.smAndDown && !form.is_masuk"
             >
               <template v-slot:activator="{ on, attrs }">
                 <v-btn
                   v-bind:data-hint="$t('introjs.this-button-for-save-as-draft')"
                   data-hintposition="middle-middle"
                   data-position="bottom-right-aligned"
                   v-bind:loading="axiosProcessing"
                   v-bind:disabled="axiosProcessing"
                   color="blue-grey"
                   class="ma-2 white--text"
                   @click="menuAction('save')"
                   v-on="on"
                   v-bind="attrs"
                 >
                   <v-icon dark class="mr-2">
                     {{ iconSave }}
                   </v-icon>
                   {{ $t("button.save") }}
                 </v-btn>
               </template>
               <span>{{ $t("tooltip.save-as-draft") }}</span>
             </v-tooltip>
 
             <v-menu
               v-if="$vuetify.breakpoint.smAndDown && !form.is_masuk"
               bottom
               left
             >
               <template v-slot:activator="{ on, attrs }">
                 <v-btn icon v-bind="attrs" v-on="on">
                   <v-icon>{{ iconMenu }}</v-icon>
                 </v-btn>
               </template>
 
               <v-list>
                 <v-list-item
                   v-for="(item, i) in mobileMenus"
                   v-bind:key="i"
                   @click="menuAction(item.command)"
                 >
                   <v-icon class="mr-3">{{ item.icon }}</v-icon>
                   <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                 </v-list-item>
               </v-list>
             </v-menu>
 
             <v-tooltip bottom>
               <template v-slot:activator="{ on, attrs }">
                 <v-btn icon @click="showHelper" v-on="on" v-bind="attrs">
                   <v-icon>{{ iconHelp }}</v-icon>
                 </v-btn>
               </template>
               <span>{{ $t("tooltip.help-me") }}</span>
             </v-tooltip>
 
             <v-tooltip bottom>
               <template v-slot:activator="{ on, attrs }">
                 <v-btn
                   icon
                   @click="createXLetter = false"
                   v-on="on"
                   v-bind="attrs"
                 >
                   <v-icon>{{ iconClose }}</v-icon>
                 </v-btn>
               </template>
               <span>{{ $t("tooltip.close-not-save") }}</span>
             </v-tooltip>
           </v-toolbar>
         </v-card-title>
 
         <v-divider></v-divider>
         <v-card-text height="100%">
           <v-row class="mt-3">
             <div
               class="pl-0 pr-0 col-sm-12"
               v-bind:class="{
                 'col-md-9': userSekper,
                 'col-md-10': !userSekper,
               }"
             >
               <v-alert
                 v-if="$v.form.approver_x.$error && !$v.form.approver_x.required"
                 dense
                 type="error"
                 v-bind:icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0"
               >
                 {{ $t("error.select-exactly-one-approver") }}
               </v-alert>
 
               <v-autocomplete
                 v-bind:data-hint="$t('introjs.this-is-letter-x-approver')"
                 data-hintposition="middle-middle"
                 data-position="bottom-left-aligned"
                 hide-details="true"
                 v-model="form.approver_x"
                 v-bind:disabled="isUpdating"
                 v-bind:items="approverList"
                 v-bind:menu-props="{
                   closeOnClick: true,
                   closeOnContentClick: true,
                 }"
                 hide-selected
                 deletable-chips
                 small-chips
                 v-bind:search-input.sync="searchInput"
                 @change="searchInput = ''"
                 chips
                 color="blue-grey lighten-2"
                 v-bind:label="$t('label.select-letter-signer')"
                 item-text="nama"
                 item-value="id"
                 multiple
                 clearable
               >
                 <template v-slot:selection="data">
                   <v-chip
                     v-bind="data.attrs"
                     v-bind:input-value="data.selected"
                     close
                     @click="data.select"
                     @click:close="removeApprover(data.item)"
                   >
                     <v-avatar left>
                       <v-icon class="mr-2">
                         {{ iconUser }}
                       </v-icon>
                     </v-avatar>
                     {{ data.item.nama }}
                   </v-chip>
                 </template>
 
                 <template v-slot:item="data">
                   <template>
                     <v-list-item-avatar>
                       <v-icon>
                         {{ iconUser }}
                       </v-icon>
                     </v-list-item-avatar>
                     <v-list-item-content>
                       <v-list-item-title
                         v-html="data.item.nama"
                       ></v-list-item-title>
                       <v-list-item-subtitle
                         v-html="data.item.kelompok"
                       ></v-list-item-subtitle>
                     </v-list-item-content>
                   </template>
                 </template>
               </v-autocomplete>
             </div>
             <div
               class="pa-0 vertical-all-child col-sm-12"
               v-bind:class="{ 'col-md-3': userSekper, 'col-md-2': !userSekper }"
             >
               <v-tooltip bottom>
                 <template v-slot:activator="{ on, attrs }">
                   <div
                     v-on="on"
                     v-bind="attrs"
                     class="avoore-icon-checkbox checkbox--primary checkbox--share"
                   >
                     <input id="chk-cc" type="checkbox" v-model="hasCC" />
                     <label for="chk-cc"></label>
                   </div>
                 </template>
                 <span>{{ $t("tooltip.add-cc") }}</span>
               </v-tooltip>
               <v-tooltip bottom>
                 <template v-slot:activator="{ on, attrs }">
                   <div
                     v-on="on"
                     v-bind="attrs"
                     class="avoore-icon-checkbox checkbox--primary checkbox--hourglass ml-2"
                   >
                     <input
                       id="chk-urgent"
                       type="checkbox"
                       v-model="form.urgensi"
                     />
                     <label for="chk-urgent"></label>
                   </div>
                 </template>
                 <span>{{ $t("tooltip.this-letter-is-urgent") }}</span>
               </v-tooltip>
             </div>
           </v-row>
           <v-row>
             <v-col cols="12" class="pl-0 pr-0">
               <v-alert
                 v-if="
                   $v.form.kepada_line_1.$error &&
                   !$v.form.kepada_line_1.required
                 "
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
               >
                 {{ $t("error.letter-recipient-title-must-be-filled") }}
               </v-alert>
 
               <v-alert
                 v-if="
                   $v.form.kepada_line_1.$error &&
                   !$v.form.kepada_line_1.maxLength
                 "
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
               >
                 {{ $t("error.letter-recipient-title-max-length") + " 10 " }}
               </v-alert>
               <multiselect
                 v-model="form.kepada_line_1"
                 :tag-placeholder="$t('text.add recipient')"
                 :placeholder="$t('text.type to add x recipient')"
                 label="name"
                 track-by="code"
                 :options="listPenerima"
                 :multiple="true"
                 :taggable="true"
                 @tag="addPenerima"
                 class="mt-5"
               >
               </multiselect>
 
               <label class="blue--text caption">{{
                 $t("text.example to kepala dinas dukcapil")
               }}</label>
             </v-col>
           </v-row>
 
           <v-row>
             <v-col cols="10" class="pl-0 pr-0">
               <v-alert
                 v-if="
                   $v.form.kepada_line_2.$error &&
                   !$v.form.kepada_line_2.required
                 "
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
               >
                 {{ $t("error.letter-recipient-desc-address-must-be-filled") }}
               </v-alert>
               <v-alert
                 v-if="
                   $v.form.kepada_line_2.$error &&
                   !$v.form.kepada_line_2.minLength
                 "
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
               >
                 {{
                   $t("error.letter-recipient-desc-address-min-length") +
                   " 5 " +
                   $t("label.characters")
                 }}
               </v-alert>
               <v-alert
                 v-if="
                   $v.form.kepada_line_2.$error &&
                   !$v.form.kepada_line_2.maxLength
                 "
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
               >
                 {{
                   $t("error.letter-recipient-desc-address-max-length") +
                   " 120 " +
                   $t("label.characters")
                 }}
               </v-alert>
               <v-text-field
                 :data-hint="$t('introjs.this-is-recipient-line-2')"
                 data-hintposition="middle-middle"
                 data-position="bottom-right-aligned"
                 v-model="form.kepada_line_2"
                 hide-details="true"
                 maxlength="250"
                 :hint="$t('tooltip.recipient-line-2')"
                 :label="$t('label.recipient-line-2')"
               ></v-text-field>
               <label class="blue--text caption">{{
                 $t("text.tick address to show di")
               }}</label>
             </v-col>
             <v-col cols="2" class="pl-0 pr-0">
               <v-alert
                 v-if="
                   $v.form.kepada_alamat.$error &&
                   !$v.form.kepada_alamat.required
                 "
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 ml-1 mb-0 mt-4"
               >
                 {{ $t("error.is this the address") }}
               </v-alert>
               <div
                 class="avoore-checkbox--responsive mt-6 pl-4"
                 :title="$t('tooltip.example-at-jakarta')"
               >
                 <label
                   class="avoore-checkbox path"
                   v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                 >
                   <input
                     type="radio"
                     class="d-inline-flex"
                     v-model="form.kepada_alamat"
                     :value="2"
                     id="alamat_2"
                   />
                   <svg viewBox="0 0 21 21">
                     <path
                       d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                     ></path>
                   </svg>
                   <label for="alamat_2" class="checkbox-title d-inline-flex">{{
                     $t("label.at")
                   }}</label>
                 </label>
               </div>
             </v-col>
           </v-row>
           <v-row>
             <v-col cols="10" class="pl-0 pr-0">
               <v-alert
                 v-if="
                   $v.form.kepada_line_3.$error &&
                   !$v.form.kepada_line_3.required
                 "
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
               >
                 {{ $t("error.letter-recipient-desc-address-must-be-filled") }}
               </v-alert>
               <v-alert
                 v-if="
                   $v.form.kepada_line_3.$error &&
                   !$v.form.kepada_line_3.minLength
                 "
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
               >
                 {{
                   $t("error.letter-recipient-desc-address-min-length") +
                   " 5 " +
                   $t("label.characters")
                 }}
               </v-alert>
               <v-alert
                 v-if="
                   $v.form.kepada_line_3.$error &&
                   !$v.form.kepada_line_3.maxLength
                 "
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
               >
                 {{
                   $t("error.letter-recipient-desc-address-max-length") +
                   " 120 " +
                   $t("label.characters")
                 }}
               </v-alert>
               <v-text-field
                 :data-hint="$t('introjs.this-is-recipient-line-3')"
                 data-hintposition="middle-middle"
                 data-position="bottom-right-aligned"
                 v-model="form.kepada_line_3"
                 hide-details="true"
                 maxlength="250"
                 :hint="$t('tooltip.recipient-line-3')"
                 :label="$t('label.recipient-line-3')"
               ></v-text-field>
               <label class="blue--text caption">{{
                 $t("text.tick address to show di")
               }}</label>
             </v-col>
             <v-col cols="2" class="pl-0 pr-0">
               <v-alert
                 v-if="
                   $v.form.kepada_alamat.$error &&
                   !$v.form.kepada_alamat.required
                 "
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 ml-1 mb-0 mt-4"
               >
                 {{ $t("error.is this the address") }}
               </v-alert>
               <div
                 class="avoore-checkbox--responsive mt-6 pl-4"
                 :title="$t('tooltip.example-at-jakarta')"
               >
                 <label
                   class="avoore-checkbox path"
                   v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
                 >
                   <input
                     type="radio"
                     class="d-inline-flex"
                     v-model="form.kepada_alamat"
                     :value="3"
                     id="alamat_3"
                   />
                   <svg viewBox="0 0 21 21">
                     <path
                       d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
                     ></path>
                   </svg>
                   <label for="alamat_3" class="checkbox-title d-inline-flex">{{
                     $t("label.at")
                   }}</label>
                 </label>
               </div>
             </v-col>
           </v-row>
 
           <v-row class="mt-3" v-if="hasCC">
             <v-col cols="12" md="12" sm="12" class="pl-0 pr-0">
               <v-alert
                 v-if="$v.form.tembusan.$error && !$v.form.tembusan.required"
                 dense
                 type="error"
                 v-bind:icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mb-0 mt-4"
               >
                 {{ $t("error.type-at-least-one-memo-cc") }}
               </v-alert>
 
               <v-alert
                 v-if="recipientCcSimilar"
                 dense
                 type="error"
                 v-bind:icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mb-2"
               >
                 {{ $t("error.recipient-and-cc-must-not-have-the-same-items") }}
               </v-alert>
 
               <multiselect
                 v-model="form.tembusan"
                 tag-placeholder="Tambah Tembusan"
                 placeholder="Cari atau tambah tembusan"
                 label="name"
                 track-by="code"
                 :options="listTembusan"
                 :multiple="true"
                 :taggable="true"
                 @tag="addTembusan"
               >
               </multiselect>
             </v-col>
           </v-row>
 
           <v-row>
             <v-col cols="12" class="pl-0 pr-0">
               <v-alert
                 v-if="$v.form.hal.$error && !$v.form.hal.required"
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
               >
                 {{ $t("error.letter-subject-must-be-filled") }}
               </v-alert>
               <v-alert
                 v-if="$v.form.hal.$error && !$v.form.hal.minLength"
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
               >
                 {{
                   $t("error.letter-subject-min-length") +
                   " 10 " +
                   $t("label.characters")
                 }}
               </v-alert>
               <v-alert
                 v-if="$v.form.hal.$error && !$v.form.hal.maxLength"
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
               >
                 {{
                   $t("error.letter-subject-max-length") +
                   " 250 " +
                   $t("label.characters")
                 }}
               </v-alert>
               <v-text-field
                 :data-hint="$t('introjs.this-is-letter-subject')"
                 data-hintposition="middle-middle"
                 data-position="bottom-right-aligned"
                 v-model="form.hal"
                 hide-details="true"
                 maxlength="250"
                 :hint="$t('tooltip.min-max-char-for-letter-subject')"
                 :label="$t('label.letter-subject')"
               >
               </v-text-field>
             </v-col>
           </v-row>
 
           <v-row>
             <v-col cols="12" class="pl-0 pr-0">
               <v-alert
                 v-if="$v.form.isi.$error && !$v.form.isi.required"
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mb-4"
               >
                 {{ $t("error.memo-message-must-be-filled") }}
               </v-alert>
               <v-alert
                 v-if="$v.form.isi.$error && !$v.form.isi.minLength"
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
               >
                 {{
                   $t("error.memo-message-min-length") +
                   " 27 " +
                   $t("label.characters")
                 }}
               </v-alert>
               <v-alert
                 v-if="$v.form.isi.$error && !$v.form.isi.maxLength"
                 dense
                 type="error"
                 :icon="iconAlert"
                 class="modal-alert v-alert--narrow v-alert--bubble bottom--left mt-4 mb-0 mt-4"
               >
                 {{
                   $t("error.memo-message-max-length") +
                   " 120000 " +
                   $t("label.characters")
                 }}
               </v-alert>
             </v-col>
             <froala-editor
               v-bind:data-hint="$t('introjs.this-is-memo-message')"
               data-hintposition="middle-middle"
               data-position="bottom-right-aligned"
               v-bind:oldEditorData="form.isi"
               v-on:editorData="changeEditorData"
               v-on:froalaIsWorking="froalaIsWorking"
               v-bind:editorDisabled="editorDisabled"
               v-on:saveOnMe="saveThis"
             >
             </froala-editor>
           </v-row>
           <v-row>
             <v-col cols="12" md="6" sm="12">
               <div
                 class="attachment-list mt-4"
                 v-if="form.attachments && form.attachments.length > 0"
               >
                 <p
                   class="mb-1 text--avore-grey"
                   v-html="$t('text.new-attachment-list')"
                 ></p>
 
                 <ol>
                   <li
                     v-for="item in form.attachments"
                     v-bind:key="item.id"
                     v-bind:value="item.id"
                     class="file--list"
                   >
                     <a href="javascript:;">
                       {{ item.name }} - {{ item.size | formatSize }}
                       <span v-if="item.error">{{ item.error }}</span>
                       <span v-else-if="item.success">success</span>
                       <span v-else-if="item.active">active</span>
                       <span v-else></span>
 
                       <v-tooltip bottom>
                         <template v-slot:activator="{ on, attrs }">
                           <v-btn
                             icon
                             class="ml-2"
                             color="red"
                             v-on:click="deleteAttachment(item)"
                             v-on="on"
                             v-bind="attrs"
                           >
                             <v-icon>{{ iconDelete }}</v-icon>
                           </v-btn>
                         </template>
                         <span>{{ $t("tooltip.delete-this-file") }}</span>
                       </v-tooltip>
                     </a>
                   </li>
                 </ol>
               </div>
               <v-tooltip top>
                 <template v-slot:activator="{ on, attrs }">
                   <file-upload
                     input-id="create-x-letter"
                     v-on="on"
                     v-bind="attrs"
                     class="mt-4 att-button v-btn v-btn--is-elevated v-btn--has-bg v-size--default blue-grey"
                     post-action="/upload/post"
                     extensions="jpg,jpeg,png,pdf,doc,docx,xls,xlsx,zip,rar,ppt,pptx"
                     accept="image/png, image/jpeg, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation
                     , application/vnd.rar, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword,
                     application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip"
                     v-bind:multiple="true"
                     v-bind:size="1024 * 1024 * 35"
                     v-bind:active="true"
                     v-bind:maximum="totalAttCount"
                     v-model="form.attachments"
                     v-on:input-filter="inputFilter"
                     v-on:input-file="inputFile"
                     ref="xupload"
                     v-bind:title="$t('tooltip.add-attachments')"
                   >
                     <v-icon class="text--white">{{ iconAttachment }}</v-icon>
                   </file-upload>
                 </template>
                 <span>{{ $t("tooltip.add-attachments") }}</span>
               </v-tooltip>
             </v-col>
           </v-row>
         </v-card-text>
         <v-overlay v-bind:absolute="true" v-bind:value="editorIsWorking">
         </v-overlay>
       </v-card>
 
       <v-card v-if="!approverXList" style="height: 100% !important">
         <v-skeleton-loader
           class="mx-auto"
           max-width="100%"
           width="100%"
           type="actions, article, list-item-three-line"
         >
         </v-skeleton-loader>
       </v-card>
     </v-dialog>
     <upline-selector
       v-bind:showUplineDialog="showUplineDialog"
       v-on:showHideUplineDialog="showHideUplineDialog"
       v-on:uplineSelected="uplineSelected"
     ></upline-selector>
 
     <peer-selector
       v-bind:showPeerDialog="showPeerDialog"
       v-on:showHidePeerDialog="showHidePeerDialog"
       v-on:peerSelected="peerSelected"
     ></peer-selector>
   </div>
 </template>
 <script>
 import { mapActions, mapState } from "vuex";
 import froalaEditor from "./FroalaEditor";
 import VueUploadComponent from "vue-upload-component";
 import uplineSelector from "./UplineSelector.vue";
 import peerSelector from "./PeerSelector.vue";
 import Multiselect from "vue-multiselect";
 import { http } from "../../http/http";
 import {
   mdiContentSave,
   mdiMenu,
   mdiSend,
   mdiCheck,
   mdiClose,
   mdiBookArrowUp,
   mdiAccountGroup,
   mdiAccount,
   mdiAlertCircleOutline,
   mdiHelpCircleOutline,
   mdiAttachment,
   mdiDeleteForever,
 } from "@mdi/js";
 
 import toolbarMenu from "@/constants/toolbarmenu";
 import { validationMixin } from "vuelidate";
 import introJS from "intro.js/intro.js";
 import "intro.js/minified/introjs.min.css";
 
 const createHelper = introJS();
 
 const {
   required,
   requiredIf,
   minLength,
   maxLength,
 } = require("vuelidate/lib/validators");
 
 export default {
   name: "create-x-letter-memo-modal",
   components: {
     froalaEditor,
     "file-upload": VueUploadComponent,
     uplineSelector,
     peerSelector,
     multiselect: Multiselect,
   },
   data() {
     // const srcs = {
     //   1: "https://image.flaticon.com/icons/png/128/709/709722.png"
     // };
     return {
       iconSave: mdiContentSave,
       iconSubmit: mdiSend,
       iconSubmitUpline: mdiBookArrowUp,
       iconMenu: mdiMenu,
       iconClose: mdiClose,
       iconGroup: mdiAccountGroup,
       iconUser: mdiAccount,
       iconAlert: mdiAlertCircleOutline,
       iconHelp: mdiHelpCircleOutline,
       iconAttachment: mdiAttachment,
       iconDelete: mdiDeleteForever,
       iconCheck: mdiCheck,
       menus: toolbarMenu.createDraftToolbarMenu,
       hasCC: false,
       editorIsWorking: false,
       timeout: null,
       loader: null,
       axiosProcessing: false,
       autoUpdate: true,
       isUpdating: false,
       searchInput: "",
       totalAttSize: 0,
       totalAttCount: 5,
       rules: [(v) => v.length <= 500 || this.$t("tooltip.max-500-char")],
       subjectTextRules: [
         (v) => !!v || this.$t("tooltip.this-field-is-required"),
         (v) =>
           (v && v.length >= 10) || this.$t("tooltip.this-field-min-10-char"),
         (v) =>
           (v && v.length <= 250) || this.$t("tooltip.this-field-max-250-char"),
       ],
       listTembusan: [],
       listPenerima: [],
       form: {
         tembusan: [],
         id: undefined,
         _method: undefined,
         action: "save",
         is_rahasia: undefined,
         urgensi: undefined,
         attachments: undefined,
         approver_x: undefined,
         kepada_line_1: [],
         kepada_line_2: undefined,
         kepada_line_3: undefined,
         kepada_alamat: undefined, //ini adalah penanda row kepada_line_x (x:line number) alamat penerima; contoh : di Jakarta, di Medan
         is_eksternal: 1,
         hal: undefined,
         isi: undefined,
         reviser_nik: undefined,
         initiator_nik: undefined,
         selectedUpline: undefined,
       },
       showUplineDialog: false,
       showPeerDialog: false,
       finalData: null,
     };
   },
   mixins: [validationMixin],
   validations: {
     form: {
       kepada_alamat: { required },
       kepada_line_1: { required, maxLength: maxLength(20) },
       kepada_line_2: {
         required,
         minLength: minLength(5),
         maxLength: maxLength(120),
       },
       kepada_line_3: {
         required: requiredIf(function () {
           return parseInt(this.form.kepada_alamat) === 3;
         }),
         minLength: minLength(5),
         maxLength: maxLength(120),
       },
       tembusan: {
         required: requiredIf(function () {
           return this.hasCC;
         }),
       },
       approver_x: { required },
       hal: { required, minLength: minLength(10), maxLength: maxLength(250) },
       isi: { required, minLength: minLength(27), maxLength: maxLength(120000) },
     },
   },
   computed: {
     ...mapState("resources", ["memoRecipient", "approverXList"]),
     ...mapState("draft", ["draftMode", "filterParams", "submitStatus"]),
     ...mapState("user", ["currentUser"]),
     ...mapState(["helpGroupRecipientDialog", "tipToHide"]),
     recipientCcSimilar() {
       return false;
       // if (!this.form.kepada_line_1 && !this.form.tembusan) return false;
       // return this.similarItemExist(this.form.kepada_line_1, this.form.tembusan);
     },
     userIsPjp() {
       if (!this.currentUser) return false;
       return ["PJP", "PJP_KANPUS"].includes(this.currentUser.usertype_elemen);
     },
     mobileMenus() {
       if (
         ["KRANI", "SEKRETARIS_KRANI"].includes(this.currentUser.usertype_elemen)
       ) {
         return toolbarMenu.createDraftToolbarMenuMobileKrani;
       } else {
         return toolbarMenu.createDraftToolbarMenuMobile;
       }
     },
     userIsKrani() {
       if (!this.currentUser) return false;
       return ["KRANI", "SEKRETARIS_KRANI"].includes(
         this.currentUser.usertype_elemen
       );
     },
     userHQ() {
       if (!this.currentUser) return false;
       return this.currentUser.kode_unit === "00";
     },
     userSekper() {
       if (!this.currentUser) return false;
       return (
         this.currentUser.kode_unit === "00" &&
         this.currentUser.kode_bagian == "BAG237"
       );
     },
     createXLetter: {
       get() {
         return this.$store.state.createXLetter;
       },
       set(val) {
         this.formReset();
         this.$store.commit("SET_CREATE_X_LETTER", val);
       },
     },
     approverList() {
       if (!this.approverXList) return [];
       return this.approverXList;
     },
     editorDisabled() {
       if (
         !this.form.kepada_line_1 ||
         !this.form.kepada_line_2 ||
         !this.form.approver_x ||
         !this.form.hal
       )
         return true;
       if (this.form.kepada_line_1.length > 0 && this.form.hal.length > 9)
         return false;
       return true;
     },
   },
 
   watch: {
     createXLetter(val) {
       //empty oldData everytime modal is showing;
       this.formReset();
       if (!val) {
         this.setMemoToReply(undefined);
         return createHelper.hideHints();
       }
 
       this.resetRecipientPeerUpline();
       // this.getDataResource({
       //   source: "memo-recipients",
       //   bgProcess: true,
       // });
       this.getDataResource({
         source: "approverxlist",
         bgProcess: true,
       });
       this.getDataResource({
         source: "peer-revisers",
         bgProcess: true,
       });
       this.getDataResource({
         source: "atasan",
         bgProcess: true,
       });
     },
     loader() {
       const l = this.loader;
       this[l] = !this[l];
 
       setTimeout(() => {
         this[l] = false;
         this.loader = null;
         this.createXLetter = false;
       }, 3000);
     },
     tipToHide(val) {
       if (!val) return;
       createHelper.hideHint(val);
     },
   },
   methods: {
     ...mapActions("resources", ["resetRecipientPeerUpline", "getDataResource"]),
     ...mapActions(["setMessageBox"]),
     ...mapActions("draft", ["setMemoToReply", "loadDraft"]),
     addTembusan(newTag) {
       let cleanText = newTag.replace(/,/g, ";");
       const tag = {
         name: cleanText,
         code: cleanText.substring(0, 2) + Math.floor(Math.random() * 10000000),
       };
       this.listTembusan.push(tag);
       this.form.tembusan.push(tag);
     },
     addPenerima(newTag) {
       let cleanText = newTag.replace(/,/g, ";");
       const tag = {
         name: cleanText,
         code: cleanText.substring(0, 2) + Math.floor(Math.random() * 10000000),
       };
       this.listPenerima.push(tag);
       this.form.kepada_line_1.push(tag);
     },
     changeEditorData(e) {
       this.form.isi = e;
     },
     showHideUplineDialog(val) {
       this.showUplineDialog = val;
     },
     showHidePeerDialog(val) {
       this.showPeerDialog = val;
     },
     removeXApprover(item) {
       const index = this.form.approver_x.indexOf(item.nik);
       if (index >= 0) this.form.approver_x.splice(index, 1);
     },
     formReset() {
       this.$v.$reset();
       this.axiosProcessing = false;
       this.hasCC = false;
       this.form = {
         tembusan: [],
         id: undefined,
         _method: undefined,
         action: "save",
         is_rahasia: undefined,
         urgensi: undefined,
         attachments: undefined,
         approver_x: undefined,
         kepada_line_1: [],
         kepada_line_2: undefined,
         kepada_line_3: undefined,
         kepada_alamat: undefined, //ini adalah penanda row kepada_line_x (x:line number) alamat penerima; contoh : di Jakarta, di Medan
         is_eksternal: 1,
         hal: undefined,
         isi: "",
         reviser_nik: undefined,
         initiator_nik: undefined,
         selectedUpline: undefined,
       };
       if (this.form.attachments && this.form.attachments.length > 0) {
         this.$refs.xupload.clear();
       }
     },
     menuAction(command) {
       if (!command) return;
       this.$v.$touch();
       if (this.$v.$invalid) return;
       this.form.action = command;
 
       switch (command) {
         case "save":
           this.saveThis();
           break;
         case "submit":
         case "direct_send":
           this.submitThis();
           break;
         case "share_to_revision":
           this.shareThis();
           break;
         case "help":
           this.showHelper();
           break;
         default:
           break;
       }
     },
     objToStr(objData) {
       if (!objData) return "";
       let result = objData
         .map(function (e) {
           return e.name;
         })
         .join(",");
 
       return result;
     },
     saveThis() {
       this.selectedUpline = null;
       this.finalData = this.clearFormData(this.form);
 
       //penerima line1 ganti dr object menjadi comma delimited string
       this.finalData.delete("kepada_line_1");
       this.finalData.append(
         "kepada_line_1",
         this.objToStr(this.form.kepada_line_1)
       );
 
       if (this.form.tembusan) {
         this.finalData.delete("tembusan");
         this.finalData.append("tembusan", this.objToStr(this.form.tembusan));
       }
 
       // this.axiosProcessing = true;
 
       this.submitMyDraft({
         action: "save",
         isSuratX: true,
         formData: this.finalData,
       });
       this.$nextTick(() => {
         // this.axiosProcessing = false;
         // if (!autoSave) {
         //   if (this.timeout) clearTimeout(this.timeout);
         //   this.timeout = setTimeout(() => {
         //     this.$store.commit("SET_CREATE_X_LETTER", false);
         //   }, 500);
         // } else {
         //   this.$dialog.notify.info(
         //     this.$t("msgbox.this-draft-has-autosaved-by-image-upload"),
         //     {
         //       position: "top-left",
         //       timeout: 5000,
         //     }
         //   );
         // }
       });
     },
     submitThis() {
       if (
         ["KRANI", "SEKRETARIS_KRANI"].includes(
           this.currentUser.usertype_elemen
         ) &&
         !this.form.is_masuk &&
         !this.form.selectedUpline
       ) {
         return (this.showUplineDialog = true);
         //open upline selector if krani
       }
 
       this.finalData = this.clearFormData(this.form);
       this.finalData.delete("selectedUpline");
       if (this.form.selectedUpline)
         this.finalData.append("initiator_nik", this.form.selectedUpline);
 
       //penerima line1 ganti dr object menjadi comma delimited string
       this.finalData.delete("kepada_line_1");
       this.finalData.append(
         "kepada_line_1",
         this.objToStr(this.form.kepada_line_1)
       );
 
       if (this.form.tembusan) {
         this.finalData.delete("tembusan");
         this.finalData.append("tembusan", this.objToStr(this.form.tembusan));
       }
 
       // this.axiosProcessing = true;
       this.submitMyDraft({
         action: "submit",
         isSuratX: true,
         formData: this.finalData,
       });
       this.$nextTick(() => {
         // this.axiosProcessing = false;
         // if (this.timeout) clearTimeout(this.timeout);
         // this.timeout = setTimeout(() => {
         //   this.$store.commit("SET_CREATE_X_LETTER", false);
         // }, 500);
       });
     },
     shareThis() {
       if (
         ["KARPIM", "KARPIM_KANPUS", "KASUBAG", "KADIS"].includes(
           this.currentUser.usertype_elemen
         ) &&
         !this.form.reviser_nik
       ) {
         return (this.showPeerDialog = true);
         //open upline selector if krani
       }
       this.finalData = this.clearFormData(this.form);
 
       //penerima line1 ganti dr object menjadi comma delimited string
       this.finalData.delete("kepada_line_1");
       this.finalData.append(
         "kepada_line_1",
         this.objToStr(this.form.kepada_line_1)
       );
 
       if (this.form.tembusan) {
         this.finalData.delete("tembusan");
         this.finalData.append("tembusan", this.objToStr(this.form.tembusan));
       }
 
       // this.axiosProcessing = true;
 
       this.submitMyDraft({
         action: "share_to_revision",
         isSuratX: true,
         formData: this.finalData,
       });
       this.$nextTick(() => {
         // this.axiosProcessing = false;
         // if (this.timeout) clearTimeout(this.timeout);
         // this.timeout = setTimeout(() => {
         //   this.$store.commit("SET_CREATE_X_LETTER", false);
         // }, 500);
       });
     },
 
     inputFilter(newFile, oldFile, prevent) {
       if (newFile && !oldFile) {
         if (
           parseInt(newFile.file.size) >= 35000000 ||
           parseInt(newFile.file.size) + parseInt(this.totalAttSize) >= 35000000
         ) {
           this.$dialog.notify.error(
             this.$t("error.attachment-size-max-35-mb"),
             {
               position: "top-left",
               timeout: 3000,
             }
           );
           return prevent();
         }
         if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
           return prevent();
         }
         if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
           return prevent();
         }
       }
     },
     inputFile(newFile, oldFile) {
       if (newFile && !oldFile) {
         // add
         this.totalAttSize += parseInt(newFile.size);
         if (!this.form.attachments) return;
         if (this.form.attachments.length === 1) {
           return this.$dialog.notify.success(
             this.$t("msgbox.attachment-will-upload-on-submit-or-save"),
             {
               position: "top-left",
               timeout: 3000,
             }
           );
         }
       }
       if (newFile && oldFile) {
         // update
         return;
       }
       if (!newFile && oldFile) {
         // remove
         //send to backend to remove by file_id
         this.totalAttSize -= parseInt(oldFile.size);
         this.deleteAttachment(oldFile);
       }
     },
 
     deleteAttachment(file) {
       if (!file) return;
       this.$refs.xupload.remove(file);
     },
     showHelper() {
       setTimeout(() => {
         createHelper.setOptions({
           hintButtonLabel: this.$t("button.i-understand"),
         });
         createHelper.addHints();
         // createHelper.refresh();
         createHelper.showHints();
       }, 500);
     },
     uplineSelected(val) {
       if (!val) return;
       this.form.selectedUpline = val.nik;
       this.menuAction("submit");
     },
     peerSelected(val) {
       if (!val) return;
       this.form.reviser_nik = val.map(({ nik }) => nik).join();
       this.menuAction("share_to_revision");
     },
     removeApprover(item) {
       const index = this.form.approver_x.indexOf(item.id);
       if (index >= 0) this.form.approver_x.splice(index, 1);
     },
     froalaIsWorking(val) {
       this.editorIsWorking = val;
     },
     async submitMyDraft(payload) {
       if (this.axiosProcessing === true) return;
       this.axiosProcessing = true;
 
       try {
         const response = await http.post(`/suratx`, payload.formData);
         if (
           response &&
           (response.status == 200 || response.status == "success")
         ) {
           switch (payload.action) {
             case "save":
               this.$dialog.info({
                 text: this.$t("msgbox.save-as-draft-success"),
                 title: this.$t("msgbox.save-success"),
                 persistent: true,
                 actions: [
                   {
                     text: this.$t("button.ok"),
                     color: "success",
                     key: false,
                     icon: mdiCheck,
                   },
                 ],
               });
               this.$store.commit("SET_CREATE_X_LETTER", false);
               break;
 
             case "submit":
               //submit to upline
               this.$dialog.info({
                 text: this.$t("msgbox.submit-draft-to-upline-success"),
                 title: this.$t("msgbox.submit-success"),
                 persistent: true,
                 actions: [
                   {
                     text: this.$t("button.ok"),
                     color: "success",
                     key: false,
                     icon: mdiCheck,
                   },
                 ],
               });
               break;
 
             case "direct_send":
               //submit to upline
               this.$dialog.info({
                 text: this.$t("msgbox.send-memo-is-success"),
                 title: this.$t("msgbox.send-memo"),
                 persistent: true,
                 actions: [
                   {
                     text: this.$t("button.ok"),
                     color: "success",
                     key: false,
                     icon: mdiCheck,
                   },
                 ],
               });
               this.$store.commit("SET_CREATE_X_LETTER", false);
               break;
             case "share_to_revision":
               //submit to peers
               this.$dialog.info({
                 text: this.$t("msgbox.submit-draft-to-peers-success"),
                 title: this.$t("msgbox.submit-success"),
                 persistent: true,
                 actions: [
                   {
                     text: this.$t("button.ok"),
                     color: "success",
                     key: false,
                     icon: mdiCheck,
                   },
                 ],
               });
               break;
 
             default:
               break;
           }
 
           this.loadDraft(this.filterParams);
 
           if (this.timeout) clearTimeout(this.timeout);
           this.timeout = setTimeout(() => {
             this.$store.commit("SET_CREATE_X_LETTER", false);
           }, 500);
         }
       } catch (error) {
         console.error(error);
         this.axiosProcessing = false;
       } finally {
         this.axiosProcessing = false;
       }
     },
   },
   filters: {
     formatSize(size) {
       if (!size) return;
       if (size > 1024 * 1024 * 1024 * 1024) {
         return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + " TB";
       } else if (size > 1024 * 1024 * 1024) {
         return (size / 1024 / 1024 / 1024).toFixed(2) + " GB";
       } else if (size > 1024 * 1024) {
         return (size / 1024 / 1024).toFixed(2) + " MB";
       } else if (size > 1024) {
         return (size / 1024).toFixed(2) + " KB";
       }
       return size.toString() + " B";
     },
   },
 };
 </script>
 <style lang="scss">
 .v-dialog.v-dialog--active.v-dialog--persistent.create-x-letter-memo-modal {
   height: 98vh !important;
   max-height: 100vh !important;
 
   .fr-element.fr-view {
     height: calc(100vh - 340px) !important;
     max-height: 100vh !important;
   }
 }
 
 @media only screen and (max-width: 767px) {
   .v-dialog.v-dialog--active.v-dialog--persistent.create-x-letter-memo-modal {
     margin: 10px !important;
 
     .fr-element.fr-view {
       height: calc(100vh - 440px) !important;
       max-height: 100vh !important;
     }
   }
 }
 
 .width-max {
   margin: 10px !important;
 }
 
 .height-95vh {
   min-height: 98vh;
 }
 </style>
 