<template>
  <v-dialog v-model="uplineDialog" persistent max-width="600" overlay-opacity=".8">
    <v-card class="pb-1">
      <v-card-title class="headline">
        {{ $t("card.select-upline") }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            {{ $t("text.to-whom-this-draft-submitted") }}
          </v-col>
          <v-col>
            <v-select
              class="mt-1"
              v-model="selectedUpline"
              clearable
              :items="uplines"
              item-text="nama"
              item-value="nik"
              :label="$t('label.select-upline')"
              persistent-hint
              return-object
              single-line
              outlined
              hide-details="true"
            ></v-select>
            <v-alert
              v-if="$v.selectedUpline.$error && !$v.selectedUpline.required"
              dense
              type="error"
              :icon="iconAlert"
              class="modal-alert v-alert--narrow v-alert--bubble top--left mb-0 mt-3"
            >
              {{ $t("error.broadcast-recipient-must-be-choosen") }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancelSelect">
          <v-icon class="'mr-2">{{iconClose}}</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>

        <v-btn color="primary darken-1" text @click="emitUpline">
          <v-icon class="'mr-2">{{iconCheck}}</v-icon>
          {{ $t("button.ok") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mdiAlertCircleOutline, mdiCheck, mdiClose } from "@mdi/js";
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");

export default {
  name: "upline-selector",
  props: {
    showUplineDialog: { type: Boolean },
  },
  data: () => ({
    selectedUpline: null,
    iconAlert: mdiAlertCircleOutline,
    iconClose: mdiClose,
    iconCheck: mdiCheck,
     items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
  }),
  mixins: [validationMixin],
  validations: {
    selectedUpline: { required },
  },
  computed: {
    ...mapState("resources", ["myUplines"]),
    uplines() {
      if (!this.myUplines) return [];
      return this.myUplines;
    },
    uplineDialog: {
      get() {
        return this.showUplineDialog;
      },
      set(val) {
        this.$emit("showHideUplineDialog", val);
      },
    },
  },
  methods: {
    emitUpline() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$emit("uplineSelected", this.selectedUpline);
      this.$emit("showHideUplineDialog", false);

      setTimeout(() => {
        this.selectedUpline = null;
         this.$v.$reset();
      }, 500);
    },
    cancelSelect() {
      this.selectedUpline = null;
      this.$v.$reset();
      this.$emit("showHideUplineDialog", false);
    },
  },
};
</script>