<template>
  <v-app-bar app clipped-left clipped-right>
    <v-toolbar-title class="align-center d-flex">
      <span class="logo-icon" v-if="$vuetify.breakpoint.smAndDown">
        <img src="../../assets/img/logo.png" width="32px"
        @click="
        $vuetify.breakpoint.smAndDown
          ? setSidebarDrawer(!sidebarDrawer)
          : $emit('input', !value)
      "
        />
      </span>
      <span class="logo-text ml-2" v-if="!$vuetify.breakpoint.smAndDown">
        <img src="../../assets/img/logo-desktop-light.svg"
        height="32px"
        class="mt-2"
         @click="
        $vuetify.breakpoint.smAndDown
          ? setSidebarDrawer(!sidebarDrawer)
          : $emit('input', !value)
      "
        />
      </span>
    </v-toolbar-title>
    <v-spacer />
   
    <div id="theme-switch">
      <v-tooltip bottom :open-on-hover="!isMobile">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="mr-2">
            <label class="avoore-switch">
              <input
                type="checkbox"
                :checked="currentThemeIsDark"
                @change="changeTheme"
              />
              <span
                :data-on="$t('label.dark')"
                :data-off="$t('label.light')"
              ></span>
            </label>
          </div>
        </template>
        <span>{{ $t("tooltip.dark-mode") }}</span>
      </v-tooltip>
    </div>

    <v-menu bottom left>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn icon dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">
              <v-icon>{{ iconMenu }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.app-menu") }}</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in userprofile"
          :key="i"
          @click="doAction(item.slug)"
        >
          <v-icon class="mr-3">{{ item.icon }}</v-icon>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <div id="lang-switch-container">
            <v-tooltip top :open-delay="300" :open-on-hover="!isMobile">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="mr-2">
                  <label class="avoore-switch lang-switch">
                    <input
                      type="checkbox"
                      :checked="localeIsEnglish"
                      @change="changeLang"
                    />
                    <span data-on="EN" data-off="ID"></span>
                  </label>
                </div>
              </template>
              <span>{{ $t("tooltip.lang-switch") }}</span>
            </v-tooltip>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations, mapActions } from "vuex";
import { mdiMenu } from "@mdi/js";
import appMenu from "@/constants/appmenu";
export default {
  name: "Header",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    iconMenu: mdiMenu,
    userprofile: appMenu.appHeaderMenu,
    href() {
      return undefined;
    },
    multiLine: true,
    snackbar: false,
    text: `I'm a multi-line snackbar.`,
  }),
  computed: {
    ...mapState([
      "sidebarDrawer",
      "currentLanguage",
      "currentThemeIsDark",
      "isMobile",
      "expandOnHover",
    ]),

    localeIsEnglish() {
      return this.currentLanguage == "en";
    },
  },
  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),
    ...mapActions(["setLang", "setThemeDark", "setExpandOnHover"]),
    ...mapActions("user", ["logout","unsubscribeNotif"]),
    changeLang(e) {
      if (e.target.checked) {
        this.setLang("en");
      } else {
        this.setLang("id");
      }
    },
    changeTheme(e) {
      this.setThemeDark(e.target.checked);
    },
    doAction(param) {
      if (param == "logout") {
        let that = this;
        if (window.location.hostname === 'localhost') return this.logout();
        this.unsubscribeNotif().then((result) =>{
          if (result.data) {
            that.logout();
            }
          else {
            that.logout();
          }
        })
          
      }
      if (param == "toggle_sidebar") {
        if (this.$vuetify.breakpoint.smAndDown) return;
        if (this.isMobile && this.expandOnHover === false) return;
        this.setExpandOnHover(!this.expandOnHover);
      }
    },
  },
};
</script>
<style>
#lang-switch-container {
  margin: 0 auto;
}
.v-label {
  font-size: 14px;
}
</style>